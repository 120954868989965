import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context';

import dotenv from '@/_env.js'

const uri = dotenv.GRAPHQL_API_URL;
const httpLink = createHttpLink({uri: uri})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      "X-User-Language": localStorage["locale"],
      authorization: localStorage["token_type"] + " " + localStorage["access_token"],
    }
  }
});

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: cache,
  connectToDevTools: true
})

export default apolloClient

import { createI18n } from 'vue-i18n'
import de from './de.js'
import en from './en.js'

const translations = {
  de: de,
  en: en,
}

//console.log(translations);

const fallbackLocale = "de";

var language = localStorage["locale"] || (window.navigator.userLanguage || window.navigator.language).split("-")[0] || fallbackLocale;

localStorage["locale"] = language;

const i18n = createI18n({
   locale: language,
   fallbackLocale: fallbackLocale,
   allowComposition: true,
   messages: translations
});

export default i18n

<template>
  <CHeader position="sticky" class="mb-4">
  </CHeader>
</template>

<script>
export default {
  name: 'SimpleAppHeader',
}
</script>

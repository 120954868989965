import { createStore } from 'vuex'

export default createStore({
  state: {
    sidebarVisible: true,
    sidebarUnfoldable: false,

    fix_update_toggle: false,
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      //AAAAAAAAAAAAAAAAAAaa
      if (document.documentElement.clientWidth < 768)
        state.fix_update_toggle = true;

      if (!state.fix_update_toggle)
        state.fix_update_toggle = true
      else
        state.sidebarVisible = payload.value
    },
  },
  actions: {},
  modules: {},
})

export default {
    dashboard: {
        show_sensor: 'Show Sensor'
    },

    generic: {
        add: "add",
        change_password: "Change Password",

        username: "Username",
        password: "Password",
        logout: "Logout",
        login: "Login",
        send: "Send",
        forgot_password: "Forgot Password",
        account_recovery: "Recover Account",
        session_expired: "Session expired",
        language: "Language",
    },

    router: {
        names: {
            home: "Home",
            dashboard: "Dashboard",
            sensor_list: "Sensors",
            sensor_data: "Sensors / Data",
            alarm_list: "Alarme",
            alarm_data: "Alarme / Daten",
            change_password: "Change Password",
            settings: "Settings",
        }
    },

    sensor_list: {
        sensor: "Sensor",
        data: "Data",
        last_value: "Last Value",
        last_active: "Last Active"
    },

    sensor_view: {
        locale: "en-US",

        range_selection: {
            date_format: "MM/dd/yyyy",
            datetime_format: "MM/dd/yyyy HH:mm:ss",

            day: "Day",
            month: "Month",
            year: "Year"
        },

        share: "Share",
        share_modal: {
            share: "Share",
            interval: "Interval duration",

            minutes_30: "30 Minutes",
            minutes_60: "1 Hour",
            hours_6: "6 Hours",
            hours_12: "12 Hours",
            hours_24: "24 Hours",
            days_3: "3 Days",
            days_7: "7 Days",
            never: "never",

            copy: "Copy",
            copied: "Copied!",
        },

        csv_export: {
            "title": "CSV export",
            "export": "export",
            "date_range": "Date range"
        },
        pdf_export: {
            "title": "PDF export",
            "export": "export",
            "date_range": "Date range"
        },

        bohrkern: {
            roadtemp: "road temperature",
            depth: "depth",
            temperature: "temperature",

            title: "Datainterval",
            text: "Daterange",

            set_interval: "Set",

            interval: {
                minutes_1: "1 Minute",
                minutes_5: "5 Minutes",
                minutes_10: "10 Minutes",
                minutes_20: "20 Minutes",
                minutes_60: "1 Hour",
                minutes_120: "2 Hours",

                errors: {
                    generic: "An error occured",
                    success: "Interval was set",

                    invalid_duration: "Interval is invalid",
                    past_duration: "Interval is in the past",
                    same_time: "start and stop are at the same time"
                }
            }
        }
    },

    phone_modal: {
        turn_phone: "Please turn the phone.",
        turn_phone_sub: "Data is only displayed in landscape format."
    },

    share: {
        expired: "Shared Link expired"
    },

    locale: {
        "en": "english",
        "de": "german",
    },

    profile: {
        error: "Error",
        new_password: "New Password",
        confirm_password: "Confirm Password",
        change_password: "Change Password",
        password_mismatch: "Passwords do not match"
    },

    alarm: {
        name: "Name",
        active: "Active",
        description: "Description",
        update: "Update",
        add: "Add",
        delete: "Delete",
        edit: "Edit",
        save: "Save",

        triggers: "Triggers",
        add_trigger: "Add Trigger",
        edit_trigger: "Edit Trigger",
        device: "Device",
        value: "Value",
        restriction_area: "Restriction Area",
        from: "from",
        to: "to",
        report: "report",
        no_device: "No Device selected",
        no_type: "No Type selected",
        from_higher_to: "'from' cannot be bigger than 'to'",

        emails: "E-Mails",
        add_email: "Add E-Mail",
        email: "E-Mail",
        delay: "Delay",

        minutes_5: "5 Minutes",
        minutes_10: "10 Minutes",
        minutes_30: "30 Minutes",
        minutes_60: "1 Hour",
        hours_2: "2 Hours",

        none: "No Type",
        above: "Above",
        below: "Below",
        between: "Between",
        outside: "Outside"
    },

    alarm_list: {
        alarm: "Alarm",
        last_alarm: "Last Alarm",
        aktiv: "Active"
    }
}

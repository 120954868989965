import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'
import MinimalLayout from '@/layouts/MinimalLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    meta: {
      title: "router.names.home",
      shared: false,
      auth: true
    },
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/sensoren/Dashboard.vue'),
        meta: {
          title: "router.names.dashboard"
        },
      },
      {
        path: '/sensoren/',
        name: 'Sensoren',
        component: () => import('@/views/sensoren/SensorList.vue'),
        meta: {
          title: "router.names.sensor_list"
        },
        children: [
        ]
      },
      {
        path: '/sensoren/:id',
        name: 'SensorDaten',
        component: () => import('@/views/sensoren/Sensor.vue'),
        meta: {
          title: "router.names.sensor_data"
        },
      },
      {
        path: '/alarme/',
        name: 'Alarm',
        component: () => import('@/views/sensoren/AlarmList.vue'),
        meta: {
          title: "router.names.alarm_list"
        },
      },
      {
        path: '/alarme/:id',
        name: 'AlarmDaten',
        component: () => import('@/views/sensoren/Alarm.vue'),
        meta: {
          title: "router.names.alarm_data"
        },
      },
      {
        path: '/settings',
        name: 'Einstellungen',
        component: () => import('@/views/sensoren/Einstellungen.vue'),
        meta: {
          title: "router.names.settings"
        },
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/sensoren/Profile.vue'),
        meta: {
          title: "router.names.profile"
        },
      },
      {
        path: '/profile/changePassword',
        name: 'Password Ändern',
        component: () => import('@/views/sensoren/Profile/changePassword.vue'),
        meta: {
          title: "router.names.change_password"
        },
      },
    ],
  },
    {
    path: '/',
    name: 'Shared Pages',
    component: MinimalLayout,
    meta: {
      shared: true,
      auth: false
    },
    children: [
      {
        path: '/share/sensoren/:share',
        name: 'Sensor Graph',
        component: () => import('@/views/sensoren/SensorShare.vue'),
      },
    ]
  },
  {
    path: '/',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '/:pathMatch(.*)*',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
        meta: {
          auth: false
        }
      },
      {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
        meta: {
          auth: false
        }
      },
      {
        path: '/recover',
        name: 'recover',
        component: () => import('@/views/pages/PasswordRecovery'),
        meta: {
          auth: false
        }
      },
      {
        path: '/recover/:recovery_id',
        name: 'recover.id',
        component: () => import('@/views/pages/Recover'),
        meta: {
          auth: false
        }
      },
      {
        path: '/coyote/:user_id/:token',
        name: 'Coyote Login',
        component: () => import('@/views/pages/Coyote'),
        meta: {
          auth: false
        }
      },
      /*
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
      */
    ],
  },
]

var isBrowser=new Function("try {return this===window;}catch(e){ return false;}");
var history_method = createWebHashHistory;

const router = createRouter({
  history: history_method(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

import axios from 'axios'
import dotenv from '@/_env.js'

router.beforeEach((to, from, next) => {
  const requires_auth = (to.meta["auth"] === undefined || to.meta.auth);
  const has_auth = "access_token" in localStorage

  if (requires_auth)
  {
    if (has_auth)
    {
      var followed_next = false;
      var last_auth = localStorage["last_auth_check"];

      if (last_auth)
      {
        last_auth = new Date(parseInt(last_auth));

        // last auth + 1 minute
        last_auth.setMinutes(last_auth.getMinutes() + 1)

        if (last_auth > new Date())
        {
          next();
          followed_next = true;
        }
      }

      var auth_header = localStorage["token_type"] + " " + localStorage["access_token"];

      axios.get(dotenv.AUTH_URL + '/user/me',
        {
          headers: {            
            "Authorization": auth_header,
          }
        },
      )
      .then(function (response) {
        localStorage["last_auth_check"] = new Date().getTime()

        if (!followed_next)
          next();
      })
      .catch(function (error) {
        localStorage["reason"] = "generic.session_expired"
        next("/login");
      })
    }
    else
    {
      next("/login");
    }
  }
  else
  {
    next();
  }

})

export default router

import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'

import { DefaultApolloClient } from '@vue/apollo-composable'
import { createApolloProvider } from '@vue/apollo-option'
import VueApolloComponents from '@vue/apollo-components'

import apolloClient from './apollo'
import i18n from './i18n'
import dotenv from '@/_env.js'
import BackendFunction from './jsonrpc'


import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
})

const app = createApp(App)

if ("SENTRY_DSN" in dotenv && dotenv["MODE"] != "development")
{
  Sentry.init({
    app,
    dsn: dotenv["SENTRY_DSN"],
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["*"],
      }),
    ],
    tracesSampleRate: 0.3,
  });
}

app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(apolloProvider)
app.use(VueApolloComponents)
app.use(i18n)

app.provide('icons', icons)
app.provide(DefaultApolloClient, apolloClient)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)
app.component('Datepicker', Datepicker);
app.component('BackendFunction', BackendFunction)

app.mount('#app')

export default {
    dashboard: {
        show_sensor: 'Sensor anzeigen'
    },

    generic: {
        add: "Hinzufügen",
        change_password: "Passwort ändern",

        username: "Nutzername",
        password: "Passwort",
        logout: "Logout",
        login: "Login",
        send: "Senden",
        forgot_password: "Passwort vergessen",
        account_recovery: "Konto-Wiederherstellung",
        session_expired: "Session abgelaufen",
        language: "Sprache",
    },

    router: {
        names: {
            home: "Home",
            dashboard: "Dashboard",
            sensor_list: "Sensoren",
            sensor_data: "Sensoren / Daten",
            alarm_list: "Alarme",
            alarm_data: "Alarme / Daten",
            change_password: "Passwort ändern",
            settings: "Einstellungen",
        }
    },

    sensor_list: {
        sensor: "Sensor",
        data: "Daten",
        last_value: "Letzter Wert",
        last_active: "Zuletzt Aktiv"
    },

    sensor_view: {
        locale: "de-DE",

        range_selection: {
            date_format: "dd.MM.yyyy",
            datetime_format: "dd.MM.yyyy HH:mm:ss",

            day: "Tag",
            month: "Monat",
            year: "Jahr"
        },

        share: "Teilen",
        share_modal: {
            share: "Teilen",
            interval: "Intervaldauer",

            minutes_30: "30 Minuten",
            minutes_60: "1 Stunde",
            hours_6: "6 Stunden",
            hours_12: "12 Stunden",
            hours_24: "24 Stunden",
            days_3: "3 Tage",
            days_7: "7 Tage",
            never: "Nie",

            copy: "Kopieren",
            copied: "Kopiert!",
        },

        csv_export: {
            title: "CSV Export",
            export: "Exportieren",
            date_range: "Datumsbereich"
        },
        pdf_export: {
            title: "PDF Export",
            export: "Exportieren",
            date_range: "Datumsbereich"
        },

        bohrkern: {
            roadtemp: "Fahrbahntemperatur",
            depth: "Tiefe",
            temperature: "Temperatur",

            title: "Datenintervall",
            text: "Datums Bereich",

            set_interval: "Setzen",

            interval: {
                minutes_1: "1 Minute",
                minutes_5: "5 Minuten",
                minutes_10: "10 Minuten",
                minutes_20: "20 Minuten",
                minutes_60: "1 Stunde",
                minutes_120: "2 Stunden",

                errors: {
                    generic: "Ein Fehler ist aufgetreten",
                    success: "Intervall wurde gesetzt",

                    invalid_duration: "Intervall ist invalide",
                    past_duration: "Intervall ist in der Vergangenheit",
                    same_time: "Start und Stop sind zur gleichen Zeit"
                }
            }
        },

        comet: {
            device_info: "Eigenschaften",
            battery_percentage: "Batterie"
        },
    },

    phone_modal: {
        turn_phone: "Bitte das Handy drehen.",
        turn_phone_sub: "Daten werden nur im Querformat angezeigt."
    },

    share: {
        expired: "Geteilter Link ist abgelaufen"
    },

    locale: {
        en: "Englisch",
        de: "Deutsch",
    },

    profile: {
        error: "Fehler",
        new_password: "Neues Passwort",
        confirm_password: "Passwort bestätigen",
        change_password: "Passwort ändern",
        password_mismatch: "Passwort stimmt nicht überein"
    },

    alarm: {
        name: "Name",
        active: "Aktiv",
        description: "Beschreibung",
        update: "Update",
        add: "Hinzufügen",
        delete: "Löschen",
        edit: "Bearbeiten",
        save: "Speichern",

        triggers: "Auslöser",
        add_trigger: "Auslöser Hinzufügen",
        edit_trigger: "Auslöser Bearbeiten",
        device: "Gerät",
        value: "Wert",
        restriction_area: "Einschränkungs Bereich",
        from: "Von",
        to: "Bis",
        report: "Report",
        no_device: "Kein Gerät Ausgewählt",
        no_type: "No Type selected",
        from_higher_to: "'from' cannot be bigger than 'to'",

        emails: "E-Mails",
        add_email: "Email Hinzufügen",
        email: "E-Mail",
        delay: "Verzögerung",

        minutes_5: "5 Minuten",
        minutes_10: "10 Minuten",
        minutes_30: "30 Minuten",
        minutes_60: "1 Stunde",
        hours_2: "2 Stunden",

        none: "Kein Typ",
        above: "Über",
        below: "Unter",
        between: "Zwischen",
        outside: "Auserhalb"
    },

    alarm_list: {
        alarm: "Alarm",
        last_alarm: "Letzter Alarm",
        aktiv: "Aktiv"
    }
}

export default {
    SENTRY_DSN: "https://ae8c4b77e09a43a7be705678db5467e4@o1096367.ingest.sentry.io/6711803",

    MODE: "production",
    GRAPHQL_API_URL: 'https://coreapi.infratest.digital/graphql',
    API_URL: 'https://coreapi.infratest.digital/api',
    AUTH_URL: 'https://coreapi.infratest.digital/oauth',
    RPC_URL: 'https://coreapi.infratest.digital/rpc',

/*    
    MODE: "development",
    GRAPHQL_API_URL: 'http://localhost:8000/graphql',
    API_URL: 'http://localhost:8000/api',
    AUTH_URL: 'http://localhost:8000/oauth',
    RPC_URL: 'http://localhost:8000/rpc',
*/
}
<template>
  <CDropdown>
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatar" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Settings
      </CDropdownHeader>

      <!--
        <CDropdownItem @click="profile()"> <CIcon icon="cil-user" /> Profile </CDropdownItem>
      -->
      <CDropdownItem @click="changePassword()"> <CIcon icon="cil-user" /> {{ $t("generic.change_password") }} </CDropdownItem>
      <CDropdownDivider />
      <CDropdownItem @click="logout()"> <CIcon icon="cil-lock-locked" /> {{ $t("generic.logout") }} </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatar.jpg'
import axios from 'axios'

import dotenv from '@/_env.js'

export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
    }
  },

  methods: {
    profile() {
      this.$router.push("/profile");
    },

    changePassword() {
      this.$router.push("/profile/changePassword");
    },

    logout() {
      delete localStorage["access_token"];
      delete localStorage["token_type"];
      // we logged out
      this.$router.push("/");
      window.location.reload();
    }
  }
}
</script>

import { h } from 'vue'
import axios from 'axios'

import dotenv from '@/_env.js'

const EXPIRE_TIME = 300; // in Seconds

function get_cache()
{
    const cache_name = "rpc_cache";
    if (!(cache_name in window))
    {
        window[cache_name] = {};
    }

    return window[cache_name];
}

function hash_value(val)
{
    var hash_val = 0;
    const type = typeof(val)
    if (type == "string")
    {
        var i;
        if (val.length === 0) return hash;
        for (i = 0; i < val.length; i++) {
            hash_val = ((hash_val << 5) - hash_val) + hash_value(val.charCodeAt(i));
        }
    }
    else if (type == "number")
    {
        hash_val = Math.floor(val);
    }
    else if (type == "object")
    {
        for (var k in val)
        {
            var v = val[k];
            hash_val += hash_value(k) + hash_value(v);
        }
    }
    return hash_val;
}

function createHash()
{
    var hash_val = 0;
    for (var k in arguments)
    {
        var v = arguments[k];
        hash_val += hash_value(k) + hash_value(v);
    }
    return hash_val
}

export default {
    name: "BackendFunction",

    props: {
        method: {
            type: String,
            default: undefined,
        },

        params: {
            type: [Array, Object],
            default: [],
        },

        disableCache: {
            type: Boolean,
            default: false,
        },

        requireAuth: {
            type: Boolean,
            default: false,
        },

        element: {
            type: String,
            default: 'div',
        }
    },

    data() {
        return {
            result: {
                result: null,
                error: null,
            },
            loading: false,
        }
    },

    watch: {
        params (value) {
            this.invoke();
        }
    },

    methods: {
        invoke() {
            var that = this;

            const request_id = Date.now();

            var data = {
                jsonrpc: "2.0",
                method: this.method,
                params: this.params,
                id: request_id,
            }

            var request_hash = -1;
            if (!this.disableCache)
            {
                request_hash = createHash(this.method, this.params);
            }

            var now_date = new Date();
            var cache = get_cache();

            if (request_hash != -1)
            {
                if (request_hash in cache)
                {
                    var cache_entry = cache[request_hash];
                    if (cache_entry["expires_at"] > now_date)
                    {
                        const data = cache_entry["data"];
                        this.result.result = data.result || null;
                        this.result.error = data.error || null;
                        return;
                    }
                }
            }

            const token_type = localStorage["token_type"];
            const access_token = localStorage["access_token"];

            var config = {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                }
            }

            if (token_type && access_token)
            {
                const auth_header = token_type + " " + access_token;
                config.headers["Authorization"] = auth_header;
            }
            else if (this.requireAuth)
            {
                throw Exception("Not able to authenticate")
            }

            this.loading = true;
            axios.post(dotenv.RPC_URL,
                data,
                config
            )
            .then((response) => {
                var data = response.data;

                if (data.id != request_id)
                    throw Exception("request ID mismatch");

                that.result.result = data.result || null;
                that.result.error = data.error || null;
                if (data.error)
                    console.error(data.error)

                if (request_hash != -1)
                {
                    now_date.setSeconds(now_date.getSeconds() + EXPIRE_TIME);
                    cache[request_hash] = {
                        "data": data,
                        "expires_at": now_date
                    };
                }

                that.loading = false;
            })
            .catch((error) => {
                console.error(error);
                that.loading = false;
            })
        }
    },

    mounted() {
        return this.invoke();
    },

    render() {
        const result = this.$slots.default({
            result: this.result,
            loading: this.loading,
        });

        return this.element ? h(this.element, result) : result
    }
}
